import {Outlet} from "react-router-dom";
import NavMenu from "./NavMenu";
import Footer from "./Footer";
import LeftSidebar from "./LeftSidebar";
import {MenuToggleProvider} from "../context/MenuToggleProvider";

const MainLayout = () => {
    return (
        <div
            className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <MenuToggleProvider>
                        <LeftSidebar/>
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <NavMenu/>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <Outlet/>
                            </div>
                            <Footer/>
                        </div>
                    </MenuToggleProvider>
                </div>
            </div>
        </div>

    )
}

export default MainLayout