import {useState, useEffect} from "react";
import {axiosPrivate} from "../../api/axios";
import DeleteIcon from "../svgIcons/DeleteIcon";

const RemoveSingleVisit = (props) => {
    const [visit, setVisit] = useState([]);

    useEffect(() => {
        setVisit(props.visitId)
    }, [props.visitId])

    const deleteVisits = () => {
        try {
            const response = axiosPrivate.delete(`/visit/${visit}`,
                {
                    headers: {'Content-Type': 'application/json'},
                }
            );
            props.delete(1)
        } catch (e) {

        }

    }

    return (
        <button onClick={deleteVisits} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
            <DeleteIcon/>
        </button>
    )
}

export default RemoveSingleVisit