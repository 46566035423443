import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useState, useEffect} from "react";

const Date = (props) => {
    const [date, setDate] = useState('');

    useEffect(() => {
        setDate('');
    }, [props.resetFilter])

    useEffect(() => {
        props.date(date)

    }, [date])

    const setFormatDate = (date) => {
        setDate(date)
    }
    return (
        <div className="input-group w-250px">
            <DatePicker selected={date} onChange={(date) => setFormatDate(date)} placeholderText={props.placeholder}/>
        </div>
    )
}

export default Date