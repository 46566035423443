import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {AuthProvider} from './context/AuthProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {CookiesProvider} from "react-cookie";
import './css/style.bundle.css'
import 'react-toastify/dist/ReactToastify.css';
import {PageTitleProvider} from "./context/PageTitleProvider";
import {ToastContainer} from "react-toastify";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <CookiesProvider>
                <AuthProvider>
                    <PageTitleProvider>
                        <ToastContainer/>
                            <Routes>
                                <Route path="/*" element={<App/>}/>
                            </Routes>
                    </PageTitleProvider>
                </AuthProvider>
            </CookiesProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);