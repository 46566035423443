import {Modal, Button} from "react-bootstrap";
import {useState, useEffect} from "react";
import BrokerCompany from "../Filters/BrokerCompany";
import ClientCompany from "../Filters/ClientCompany";
import EditOfficeType from "./EditOfficeType";
import {axiosPrivate} from "../../api/axios";
import {useCookies} from "react-cookie";
import {Form} from "react-bootstrap";
import EditIcon from "../svgIcons/EditIcon";
import {toast} from "react-toastify";
import EditOfficeSize from "./EditOfficeSize";

const EditVisitPopup = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [visit, setVisit] = useState({});
    const [newBrokerCompany, setNewBrokerCompany] = useState('');
    const [newClient, setNewClient] = useState('');
    const [newBroker, setNewBroker] = useState('');
    const [newOfficeType, setNewOfficeType] = useState('');
    const [newOfficeSize, setNewOfficeSize] = useState('');
    const [introduced, setIntroduced] = useState();
    const [firstVisit, setFirstVisit] = useState();
    const [cookie, setCookie] = useCookies();

    const [editedVisit, setEditedVisit] = useState({});

    useEffect(() => {
        if (modalShow) {
            setNewBroker(props?.visit?.broker?.name)
            setVisit(props.visit)
            setIntroduced(props.visit.introduced_via_mail)
            setFirstVisit(props.visit.first_visit)
        }else{
            props.edit(1);
        }
    }, [modalShow])


    const getBrokerCompany = (data) => {
        data.search !== visit?.broker_company?.title ? setNewBrokerCompany(data.search) : setNewBrokerCompany(visit?.broker_company?.title)
    }

    const getClientCompany = (data) => {
        data.search !== visit?.client?.name ? setNewClient(data.search) : setNewClient(visit?.client?.name)
    }

    const getOfficeType = (data) => {
        setNewOfficeType(data.officeType)
    }

    const getOfficeSize = (data) => {
        setNewOfficeSize(data.officeSize)
    }

    const editVisit = (e) => {
        e.preventDefault();
        if (newBrokerCompany !== '' && newClient !== '' && newOfficeType !== '' && newOfficeSize !== '') {

            axiosPrivate.post('visit',
                JSON.stringify({
                    visit_id: visit?.id,
                    broker_company: newBrokerCompany,
                    broker: newBroker,
                    client: newClient,
                    office_type_id: newOfficeType,
                    office_size_id: newOfficeSize,
                    introduced_via_mail: introduced,
                    first_visit: firstVisit
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        'Authorization': `Bearer ${cookie.token}`
                    }
                }
            ).then((response) => {
                notify('success', 'Visit edit successfully');
                setModalShow(false);
                props.edit(1);
            }).catch((err) => {
                notify('error', err?.response.statusText);
            });
        }else{
            notify('error', 'All fields with * are required!');
        }
    }

    const changeIntroduced = (val) => {
        setIntroduced(val);
    }

    const changeFirstVisit = (val) => {
        setFirstVisit(val);
    }

    const notify = (type, msg) => {
        if (type === 'success') {
            toast.success(msg)
        } else if (type === 'error') {
            toast.error(msg)
        }
    }

    return (
        <>
            <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    onClick={() => setModalShow(true)}>
                <EditIcon/>
            </button>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit visit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll">
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-5 fw-bold mb-2">Broker company</label>
                                <BrokerCompany company={getBrokerCompany} brokerCompanyData={{
                                    id: visit?.broker_company?.id,
                                    title: visit?.broker_company?.title
                                }} openModal={visit}/>
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="fs-5 fw-bold mb-2">Broker name</label>
                                <input type="text"
                                       className="form-control form-control-solid"
                                       value={newBroker}
                                       onChange={(e) => setNewBroker(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Client details</label>
                            <ClientCompany company={getClientCompany}
                                           clientCompanyData={{id: visit?.client?.id, name: visit?.client?.name}}
                                           openModal={visit}/>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Office type</label>
                            <EditOfficeType office={getOfficeType} officeTypeData={visit?.office_type_id}
                                            openModal={visit}/>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Office size</label>
                            <EditOfficeSize size={getOfficeSize} officeSizeData={visit?.office_size_id}
                                            openModal={visit}/>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Introduced via mail</label>
                            <Form.Select aria-label="Introduced via mail" value={introduced}
                                         className="form-select form-select-solid select2-hidden-accessible"
                                         onChange={(event => changeIntroduced(event.target.value))}>
                                <option
                                    value={0}
                                >No
                                </option>
                                <option
                                    value={1}
                                >Yes
                                </option>
                            </Form.Select>
                        </div>
                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">First visit</label>
                            <Form.Select aria-label="First visit" value={firstVisit}
                                         className="form-select form-select-solid select2-hidden-accessible"
                                         onChange={(event => changeFirstVisit(event.target.value))}>
                                <option
                                    value={0}
                                >No
                                </option>
                                <option
                                    value={1}
                                >Yes
                                </option>
                            </Form.Select>
                        </div>
                        <button className="btn btn-light me-3" onClick={editVisit}>Edit</button>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default EditVisitPopup