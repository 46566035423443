import Filter from "./Filter";
import {useState, useEffect} from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import format from "date-format";
import RemoveVisits from "./Actions/RemoveVisits";
import RemoveSingleVisit from "./Actions/RemoveSingleVisit";
import EditVisitPopup from "./Actions/EditVisitPopup";
import {toast} from "react-toastify";

const Visits = () => {
    let isMounted = true;
    const [visits, setVisits] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const [paginate, setPaginate] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [fromVisits, setFromVisits] = useState();
    const [toVisits, setToVisits] = useState();
    const [totalVisits, setTotalVisits] = useState();
    const [filter, setFilter] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [refreshVisit, setRefreshVisit] = useState(0);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getVisits = async () => {
            try {
                const response = await axiosPrivate.get('/visit', {
                    signal: controller.signal,
                    params: {
                        per_page: paginate,
                        page: currentPage,
                        broker_company_id: filter.brokerCompany,
                        client_company_id: filter.clientCompany,
                        office_size_id: filter.officeSizeId,
                        start_date: filter.startDate ? format('yyyy/MM/dd', new window.Date(filter.startDate)) : '',
                        end_date: filter.endDate ? format('yyyy/MM/dd', new window.Date(filter.endDate)) : ''
                    }
                });
                isMounted && setVisits(response.data.data);
                setCurrentPage(response.data.current_page);
                setLastPage(response.data.last_page);
                setFromVisits(response.data.from);
                setToVisits(response.data.to);
                setTotalVisits(response.data.total);
            } catch (err) {
            }
        }

        getVisits();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [currentPage, paginate, filter, refreshVisit])

    const changePage = (i) => {
        setCurrentPage(currentPage + i);
    }

    const filterParams = (data) => {
        setFilter(data)
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(visits.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const {id, checked} = e.target;
        const vId = parseInt(id);
        setIsCheck([...isCheck, vId]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== vId));
        }
    };

    const DeleteAction = () => {
        setTimeout(()=>{
            setRefreshVisit(refreshVisit + 1);
            setIsCheck([]);
            notify('success', 'Visit deleted successfully')
        }, 100)
    }
    const EditAction = () => {
        setRefreshVisit(refreshVisit + 1);
    }

    const notify = (type, msg) => {
        if (type === 'success') {
            toast.success(msg, {
                toastId: 'success',
            })
        } else if (type === 'error') {
            toast.error(msg, {
                toastId: 'error',
            })
        }
    }

    const showSize = (min, max) => {
        if (min !== null && max !== null){
            return `${min} - ${max}`;
        }else if(min === null){
            return `under ${max}`;
        }else{
            return `over ${min}`;
        }
    }

    return (
        <>
            <div className="card mb-5 mb-xl-8">
                <Filter filter={filterParams}/>
                <div className="card-body py-3">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                            <thead>
                            <tr className="fw-bolder text-muted">
                                <th className="w-25px">
                                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input type="checkbox" id="selectAll" className="form-check-input"
                                               onChange={handleSelectAll}
                                               checked={isCheckAll}/>
                                    </div>
                                </th>
                                <th>Broker company</th>
                                <th>Date</th>
                                <th>Client company</th>
                                <th>Office type</th>
                                <th>Office size</th>
                                <th>Introduced via mail</th>
                                <th>First visit</th>
                                <th className="min-w-100px text-end">Actions</th>
                            </tr>
                            </thead>
                            <tbody>

                            {visits?.length
                                ? (
                                    <>
                                        {visits.map((visit) =>
                                            <tr key={visit.id}>
                                                <td>
                                                    <div
                                                        className="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input type="checkbox"
                                                               id={visit.id}
                                                               onChange={handleClick}
                                                               checked={isCheck.includes(visit.id)}
                                                               className="form-check-input widget-13-check"/>
                                                    </div>
                                                </td>
                                                <td className="text-dark fw-bolder text-hover-primary fs-6">{visit?.broker_company?.title} </td>
                                                <td className="text-dark fw-bolder text-hover-primary fs-6"> {format('yyyy/MM/dd hh:mm', new window.Date(visit?.created_at))} </td>
                                                <td className="text-dark fw-bolder text-hover-primary fs-6"> {visit?.client?.name} </td>
                                                <td className="text-dark fw-bolder text-hover-primary fs-6"> {visit?.office_type?.title} </td>
                                                <td className="text-dark fw-bolder text-hover-primary fs-6">
                                                    {
                                                        showSize(visit?.office_size?.min, visit?.office_size?.max)
                                                    }
                                                    <sub>
                                                        {visit?.office_size?.measurement_unit}
                                                    </sub>
                                                </td>
                                                <td> {visit?.introduced_via_mail ?
                                                    <span className="badge badge-light-success">Yes</span> :
                                                    <span className="badge badge-light-danger">No</span>} </td>
                                                <td> {visit?.first_visit ?
                                                    <span className="badge badge-light-success">Yes</span> :
                                                    <span className="badge badge-light-danger">No</span>} </td>
                                                <td className="text-align-r">
                                                    <EditVisitPopup edit={EditAction} visit={visit}/>
                                                    <RemoveSingleVisit delete={DeleteAction} visitId={visit?.id}/>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ) : <tr>
                                    <td colSpan={2}><h3 className="fw-bolder m-0">No visit to display</h3></td>
                                </tr>
                            }
                            </tbody>
                        </table>
                        <div className="row px-9 pt-3 pb-5">
                            {
                                visits.length
                                    ? <div
                                        className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                        <div className="dataTables_length" id="kt_inbox_listing_length">
                                    <span>
                                        <RemoveVisits visitsId={isCheck} delete={DeleteAction}/>
                                    </span>
                                            <label>
                                                <select onChange={(e) => {
                                                    setPaginate(e.target.value);
                                                    setCurrentPage(1);
                                                }} className="form-select form-select-sm form-select-solid">
                                                    <option value="10">10</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="250">250</option>
                                                </select>
                                            </label>
                                            <span className="ml-30">{fromVisits} - {toVisits} of {totalVisits}</span>
                                        </div>
                                    </div>
                                    : ''
                            }
                            <div
                                className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                <div className="dataTables_paginate paging_simple_numbers"
                                     id="kt_inbox_listing_paginate">
                                    <ul className="pagination">
                                        <li className="paginate_button page-item"
                                            id="kt_inbox_listing_previous">
                                            {
                                                currentPage === 1
                                                    ? ''
                                                    : <button className="page-link"
                                                              onClick={() => changePage(-1)}>PREV</button>
                                            }
                                        </li>
                                        <li className="paginate_button page-item next" id="kt_inbox_listing_next">
                                            {
                                                currentPage === lastPage
                                                    ? ''
                                                    : <button className="page-link"
                                                              onClick={() => changePage(1)}>NEXT</button>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Visits
