import {useState, useEffect} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const BrokerCompany = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [brokerCompany, setBrokerCompany] = useState({});
    const [search, setSearch] = useState('');
    const [focusInput, setFocusInput] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});

    useEffect(() => {
        props.company({selectedCompany, search})
    }, [selectedCompany])

    useEffect(() => {
        setSelectedCompany({id: props?.brokerCompanyData?.id, title: props?.brokerCompanyData?.title})
        setSearch(props?.brokerCompanyData?.title)
    }, [props.openModal])

    useEffect(() => {
        setSelectedCompany({});
        setSearch('');

    }, [props.resetFilter])

    useEffect(() => {
        setTimeout(() => {
            let isMounted = true;
            const controller = new AbortController();

            const getBrokerCompany = async () => {
                try {
                    const response = await axiosPrivate.get('/client/broker-companies', {
                        signal: controller.signal,
                        params: {
                            search: search,
                            per_page: 15
                        }
                    });
                    isMounted && setBrokerCompany(response.data.data);
                } catch (err) {
                    console.error(err);
                }
            }

            getBrokerCompany();

            return () => {
                isMounted = false;
                controller.abort();
            }
        }, 1000);

    }, [search])

    const selectCompany = (id, title) => {
        setSelectedCompany({id: id, title: title})
    }

    const resetValue = (val, reset) => {
        setSearch(val);
        reset && setSelectedCompany({id: '', title: ''})
    }

    return (
        <div className="input-group w-250px">
            <input type="text"
                   placeholder="Broker company"
                   value={search}
                   onChange={(e) => resetValue(e.target.value, true)}
                   onBlur={() => setTimeout(() => setFocusInput(false), 100)}
                   onFocus={() => setFocusInput(true)}
                   className={`${props.checkCompany && 'wrong_company'} form-control form-control-solid w-250px mr-15`}
            />
            {
                focusInput
                && <div id="kt_modal_users_search_handler" className="position-relative">
                    <div className="py-5 dropdown_box w-250px">
                        <div className="">
                            <div className="mh-375px scroll-y me-n7 pe-7">
                                {brokerCompany?.length
                                    ?
                                    brokerCompany.map((company) =>
                                        <a href="#" key={company.id} onClick={() => {
                                            selectCompany(company.id, company.title);
                                            resetValue(company.title, false)
                                        }}
                                           className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1">
                                            <div className="fw-bold">
                                                                    <span
                                                                        className="fs-6 text-gray-800 me-2">{company?.title}</span>
                                            </div>
                                        </a>
                                    )
                                    :
                                    <div className="text-center">
                                        <div className="fw-bold py-10">
                                            <div className="text-gray-600 fs-3 mb-2">No company
                                                found
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default BrokerCompany
