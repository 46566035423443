import {Form} from "react-bootstrap";
import Select from "react-select";
import {axiosPrivate} from "../../api/axios";
import {useState, useEffect} from "react";

const OfficeSize = (props) => {
    const [sizes, setSizes] = useState([]);
    const [selectedSize, setSelectedSize] = useState({});

    useEffect(() => {
        props.size(selectedSize);
    }, [selectedSize]);

    useEffect(() => {
        setSelectedSize({label: 'Office size'});
    }, [props.resetFilter])


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getSizes = async () => {
            try {
                const response = await axiosPrivate.get('/client/office-size', {
                    signal: controller.signal
                });
                isMounted &&
                response.data.map((size, i) => {
                    let min = size.min;
                    let max = size.max;
                    let officeSize;

                    if (min !== null && max !== null) {
                        officeSize = `${min} - ${max} ${size.measurement_unit}`;
                    } else if (min === null) {
                        officeSize = `under ${max} ${size.measurement_unit}`;
                    } else {
                        officeSize = `over ${min} ${size.measurement_unit}`;
                    }

                    setSizes((prevState) => ([...prevState, {value: size.id, label: officeSize}]))
                });
            } catch (err) {
                console.error(err);
            }
        }

        getSizes();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#000' : '#5e6278',
            padding: 10,
            background: '#fff',
            cursor: 'pointer'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return {...provided, opacity, transition};
        }
    }

    const handleChange = (size) => {
        setSelectedSize(size)
    }

    return (
        <div className="input-group w-250px">
            <Select classNamePrefix="select"
                    isClearable
                    placeholder="Office size"
                    value={selectedSize}
                    className="custom_select"
                    options={sizes}
                    styles={customStyles}
                    onChange={(size) => handleChange(size)}
            />
        </div>
    )
}

export default OfficeSize