import { Link } from "react-router-dom"

const Missing = () => {
    return (
        <div className="d-flex flex-column flex-center flex-column-fluid p-10 full_height">
            <img src="/logo-dark.png" alt=""
                 className="mw-100 mb-20" />
                <h1 className="fw-bold mb-10 title-404" >Seems there is nothing here</h1>
                <Link to="/"> <button className="btn btn-primary">Return to Dashboard</button> </Link>
        </div>
    )
}

export default Missing
