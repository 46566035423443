import {Form} from "react-bootstrap";
import {axiosPrivate} from "../../api/axios";
import {useState, useEffect} from "react";

const EditOfficeSize = (props) => {
    const [officeSize, setOfficeSize] = useState();
    const [sizes, setSizes] = useState();

    useEffect(() => {
        props.size({officeSize})
    }, [officeSize])

    useEffect(() => {
        setOfficeSize(props.officeSizeData)
    }, [props.openModal])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getSizes = async () => {
            try {
                const response = await axiosPrivate.get('/client/office-size', {
                    signal: controller.signal
                });
                isMounted && setSizes(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getSizes();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const changeOfficeSize = (val) => {
        setOfficeSize(val)
    }
    const showSize = (min, max) => {
        if (min !== null && max !== null){
            return `${min} - ${max}`;
        }else if(min === null){
            return `under ${max}`;
        }else{
            return `over ${min}`;
        }
    }

    return (
        <Form.Select aria-label="Default select example"
                     className="form-select form-select-solid select2-hidden-accessible"
                     onChange={(event => changeOfficeSize(event.target.value))}
                     value={officeSize}
        >
            {
                sizes?.length
                    ? sizes.map((size) => <option
                        key={size.id}
                        value={size.id}
                    >
                        {
                            showSize(size?.min, size?.max)
                        }
                        {` ${size?.measurement_unit}`}
                    </option>)

                    : ''
            }
        </Form.Select>
    )
}

export default EditOfficeSize