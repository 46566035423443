import {NavLink} from "react-router-dom";
import DashboardIcon from "./svgIcons/DashboardIcon";
import RocketIcon from "./svgIcons/RocketIcon";
import {useContext} from "react";
import MenuToggleContext from "../context/MenuToggleProvider";
import ArrowLeft from "./svgIcons/ArrowLeft";

const LeftSidebar = () => {
    const {menuToggle, setMenuToggle} = useContext(MenuToggleContext)

    return (
        <div id="kt_aside" className={`aside aside-dark aside-hoverable ${menuToggle && 'drawer drawer-start drawer-on'}`} >

            <div className="mob_response aside-logo flex-column-auto" id="kt_aside_logo">
                <img alt="Logo" src="/logo-white.png" className="h-25px logo"/>
                <div id="close_mob_menu" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" onClick={() => setMenuToggle(false)}>
                    <ArrowLeft/>
                </div>
            </div>

            <div className="aside-menu flex-column-fluid">
                <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" >
                    <div
                        className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                        id="#kt_aside_menu">
                        <NavLink to="/" className={(navData) => (navData.isActive ? "active" : '')}  onClick={() => setMenuToggle(false)}>
                            <div className="menu-item menu-accordion">
									<span className="menu-link">
										<span className="menu-icon">
											<DashboardIcon/>
										</span>
										<span className="menu-title">Dashboards</span>
									</span>
                            </div>
                        </NavLink>
                        <NavLink to="/add" className={(navData) => (navData.isActive ? "active" : '')}  onClick={() => setMenuToggle(false)}>
                            <div className="menu-item menu-accordion">

									<span className="menu-link">
										<span className="menu-icon">
											<RocketIcon/>
										</span>
										<span className="menu-title">Add new</span>
									</span>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
                <a href="../../demo1/dist/documentation/getting-started.html"
                   className="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip"
                   data-bs-trigger="hover" data-bs-dismiss-="click"
                   title="200+ in-house components and 3rd-party plugins">
                    <span className="btn-label">Docs &amp; Components</span>
                    <span className="svg-icon btn-icon svg-icon-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
									<path opacity="0.3"
                                          d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z"
                                          fill="currentColor"/>
									<rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"/>
									<rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"/>
									<rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"/>
									<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"/>
								</svg>
							</span>
                </a>
            </div>
        </div>

    )
}

export default LeftSidebar;