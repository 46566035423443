const Footer = () => {
    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-bold me-1">Copyright © Central Park Towers, DIFC, Dubai 2022</span>
                </div>
            </div>
        </div>
    )
}

export default Footer