import {useState} from "react";
import {axiosPrivate} from "../../api/axios";
import {useCookies} from "react-cookie";
import {toast, ToastContainer} from "react-toastify";
import {Form} from "react-bootstrap";


const CreateOfficeSize = () => {
    const [cookie, setCookie] = useCookies();
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [unit, setUnit] = useState('sq ft');
    const [spinner, setSpinner] = useState(false);

    const notify = (type, msg) => {
        if (type === 'success') {
            toast.success(msg)
        } else if (type === 'error') {
            toast.error(msg)
        }
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        setSpinner(true);

        axiosPrivate.post('office-size',
            JSON.stringify({ min, max, measurement_unit: unit }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': `Bearer ${cookie.token}`
                }
            }
        ).then((response) => {
            notify('success', 'Office size added successfully')
            setMin('');
            setMax('');
        }).catch ((err) => {
            if (err.response?.status === 422){
                notify('error', err.response?.data?.errors?.measurement_unit ? err.response?.data?.errors?.measurement_unit[0] : err?.response.statusText)
            }else{
                notify('error', err.response?.data[0] ? err.response?.data[0] : err?.response.statusText)
            }
        });

        setSpinner(false)
    }

    return (
        <div className="card card-flush">
            <ToastContainer/>
            <div className="card-header pt-7">
                <div className="card-title">
                    <h2>New office size</h2>
                </div>
            </div>
            <div className="card-body pt-5">
                <form onSubmit={handleSubmit} className="form fv-plugins-bootstrap5 fv-plugins-framework">

                    <div className="fv-row mb-7 fv-plugins-icon-container">
                        <label className="fs-6 fw-bold form-label mt-3">
                            <span className="">Min size</span>
                        </label>
                        <input
                            type="number"
                            id="minSize"
                            autoComplete="off"
                            onChange={(e) => setMin(e.target.value)}
                            value={min}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                        <label className="fs-6 fw-bold form-label mt-3">
                            <span className="">Max size</span>
                        </label>
                        <input
                            type="number"
                            id="maxSize"
                            autoComplete="off"
                            onChange={(e) => setMax(e.target.value)}
                            value={max}
                            className="form-control form-control-solid"
                        />
                    </div>
                    <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                        <label className="required fs-5 fw-bold mb-2">Measurement unit</label>
                        <Form.Select value={unit}
                                     className="form-select form-select-solid select2-hidden-accessible"
                                     onChange={(e => setUnit(e.target.value))}>
                            <option
                                value='sq ft'
                            >sq ft
                            </option>
                        </Form.Select>
                    </div>

                    <div className="separator mb-6"></div>

                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                            {
                                spinner
                                    ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    : <span className="indicator-label">Create</span>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateOfficeSize