import {Route, Routes} from "react-router-dom";
import Visits from "./Visits";
import {useContext, useEffect} from "react";
import PageTitleContext from "../context/PageTitleProvider";

const Dashboard = () => {
    const { setPageTitle } = useContext(PageTitleContext);

    useEffect(() => {
        setPageTitle('Dashboard');
    }, [])

    return (
            <div className="dashboard">
                <Visits/>
            </div>
    )
}

export default Dashboard
