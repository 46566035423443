import {useState, useEffect} from "react";
import BrokerCompany from "./Filters/BrokerCompany";
import Date from "./Filters/Date";
import ClientCompany from "./Filters/ClientCompany";
import FilterIcon from "./svgIcons/FilterIcon";
import OfficeSize from "./Filters/OfficeSize";

const Filter = (props) => {
    const [triggerFilter, setTriggerFilter] = useState(0);
    const [brokerCompany, setBrokerCompany] = useState({id: '', title: ''});
    const [clientCompany, setClientCompany] = useState({id: '', name: ''});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [officeSize, setOfficeSize] = useState();
    const [wrongCompany, setWrongCompany] = useState({checkBrokerCompany: false, checkClientCompany: false});
    const [brokerCompanySearch, setBrokerCompanySearch] = useState('');
    const [clientCompanySearch, setClientCompanySearch] = useState('');
    const [resetFilter, setResetFilter] = useState(0)

    useEffect(() => {
        props.filter(
            {brokerCompany: brokerCompany.id, clientCompany: clientCompany.id, startDate, endDate, officeSizeId: officeSize?.value}
        )
    }, [triggerFilter])

    useEffect(() => {
        if (brokerCompany.id === '' && brokerCompanySearch !== '') {
            setWrongCompany((prevState) => ({...prevState, checkBrokerCompany: true}))
        } else {
            setWrongCompany((prevState) => ({...prevState, checkBrokerCompany: false}))
        }
    }, [brokerCompany, brokerCompanySearch])

    useEffect(() => {
        if (clientCompany?.id === '' && clientCompanySearch !== '') {
            setWrongCompany((prevState) => ({...prevState, checkClientCompany: true}))
        } else {
            setWrongCompany((prevState) => ({...prevState, checkClientCompany: false}))
        }
    }, [clientCompany, clientCompanySearch])

    const getBrokerCompany = (data) => {
        setBrokerCompany(data.selectedCompany)
        setBrokerCompanySearch(data.search)

    }

    const getClientCompany = (data) => {
        setClientCompany(data.selectedCompany)
        setClientCompanySearch(data.search)
    }

    const getStartDate = (data) => {
        setStartDate(data)
    }
    const getEndDate = (data) => {
        setEndDate(data)
    }

    const getOfficeSize = (size) => {
        setOfficeSize(size);
    }

    const clickFilter = () => {
        if (wrongCompany.checkBrokerCompany === false && wrongCompany.checkClientCompany === false) {
            setTriggerFilter(triggerFilter + 1)
        }
    }

    const clickReset = () => {
        setResetFilter(resetFilter + 1);
        setTimeout(() => {
            setTriggerFilter(triggerFilter + 1)
        }, 100)
    }

    return (
        <div className="card-header border-0 pt-6">
            <div className="card-title">
                <div className="card-toolbar flex-row-fluid gap-5">
                    <BrokerCompany company={getBrokerCompany} resetFilter={resetFilter}
                                   checkCompany={wrongCompany.checkBrokerCompany}/>
                    <ClientCompany company={getClientCompany} resetFilter={resetFilter}
                                   checkCompany={wrongCompany.checkClientCompany}/>
                    <OfficeSize size={getOfficeSize} resetFilter={resetFilter}/>
                    <Date date={getStartDate} resetFilter={resetFilter} placeholder="START DATE"/>
                    <Date date={getEndDate} resetFilter={resetFilter} placeholder="END DATE"/>
                    <button onClick={clickFilter} className="btn btn-light-primary me-3">
                        <FilterIcon/>
                    </button>
                    <button onClick={clickReset} className="btn btn-light-danger me-3">
                        Reset
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Filter
