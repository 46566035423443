import axios from '../api/axios';
import useAuth from './useAuth';
import {useCookies} from "react-cookie";

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const [cookie, setCookie] = useCookies()
    const roles = parseInt(cookie.roles);

    const refresh = () => {
        setAuth( { roles: [roles], accessToken: cookie.token });
        return cookie.token;
    }
    return refresh;
};

export default useRefreshToken;
