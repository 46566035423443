import { createContext, useState } from "react";

const MenuToggleContext = createContext({});

export const MenuToggleProvider = ({ children }) => {
    const [menuToggle, setMenuToggle] = useState(false);

    return (
        <MenuToggleContext.Provider value={{ menuToggle, setMenuToggle }}>
            {children}
        </MenuToggleContext.Provider>
    )
}

export default MenuToggleContext