import {useState} from "react";
import {axiosPrivate} from "../../api/axios";
import {useCookies} from "react-cookie";
import {toast, ToastContainer} from "react-toastify";


const CreateBroker = () => {
    const [cookie, setCookie] = useCookies();
    const [company, setCompany] = useState('');
    const [broker, setBroker] = useState('');
    const [spinner, setSpinner] = useState(false);

    const notify = (type, msg) => {
        if (type === 'success') {
            toast.success(msg)
        } else if (type === 'error') {
            toast.error(msg)
        }
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        setSpinner(true);

        axiosPrivate.post('broker-company',
            JSON.stringify({ broker_company: company, broker }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': `Bearer ${cookie.token}`
                }
            }
        ).then((response) => {
            notify('success', 'Broker added successfully')
            setCompany('')
            setBroker('')
        }).catch((err) => {
            if (err.response?.status === 422){
                notify('error', err.response?.data?.errors?.title ? err.response?.data?.errors?.title[0] : err?.response.statusText)
            }else{
                notify('error', err.response?.data[0] ? err.response?.data[0] : err?.response.statusText)
            }
        })

        setSpinner(false)
    }

    return (
        <div className="card card-flush">
            <ToastContainer/>
            <div className="card-header pt-7">
                <div className="card-title">
                    <h2>New Broker</h2>
                </div>
            </div>
            <div className="card-body pt-5">
                <form onSubmit={handleSubmit} className="form fv-plugins-bootstrap5 fv-plugins-framework">

                    <div className="fv-row mb-7 fv-plugins-icon-container">
                        <label className="fs-6 fw-bold form-label mt-3">
                            <span className="required">Broker company</span>
                        </label>
                        <input
                            type="text"
                            id="company"
                            autoComplete="off"
                            onChange={(e) => setCompany(e.target.value)}
                            value={company}
                            className="form-control form-control-solid"
                            required
                        />
                    </div>

                    <div className="fv-row mb-7 fv-plugins-icon-container">
                        <label className="fs-6 fw-bold form-label mt-3">
                            <span>Broker name</span>
                        </label>
                        <input
                            type="text"
                            id="broker"
                            onChange={(e) => setBroker(e.target.value)}
                            value={broker}
                            className="form-control form-control-solid"
                        />
                    </div>

                    <div className="separator mb-6"></div>

                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                            {
                                spinner
                                    ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    : <span className="indicator-label">Create</span>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateBroker