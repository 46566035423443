import {useState, useEffect} from "react";
import {axiosPrivate} from "../../api/axios";

const RemoveVisits = (props) => {
    const [visits, setVisits] = useState([]);

    useEffect(() => {
        setVisits(props.visitsId)
    }, [props.visitsId])

    const deleteVisits = () => {
        try {
            const response = axiosPrivate.delete('/visits',
                {
                    data: JSON.stringify({visits}),
                    headers: {'Content-Type': 'application/json'},
                }
            );
            props.delete(1)
        } catch (e) {

        }

    }

    return (
        <>
            {
                visits.length
                    ? <button className="btn btn-sm btn-danger mr-30" onClick={deleteVisits}>Delete</button>
                    : ''
            }
        </>
    )
}

export default RemoveVisits