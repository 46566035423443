import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import {Routes, Route} from 'react-router-dom';
import AddNew from "./components/AddNew";
import MainLayout from "./components/MainLayout";

const ROLES = {
    'Admin': 1
}

function App() {

    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                {/* public routes */}
                <Route path="login" element={<Login/>}/>
                <Route path="unauthorized" element={<Unauthorized/>}/>

                {/* protected routes */}
                <Route path="/" element={<PersistLogin/>}>

                    <Route path="/" element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}>
                        <Route path="/" element={<MainLayout/>}>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="/add" element={<AddNew/>}/>
                        </Route>
                    </Route>

                </Route>

                {/* catch all */}
                <Route path="/*" element={<Missing/>}/>
            </Route>
        </Routes>
    );
}

export default App;