import CreateBroker from "./Actions/CreateBroker";
import CreateOffice from "./Actions/CreateOffice";
import {useContext, useEffect} from "react";
import PageTitleContext from "../context/PageTitleProvider";
import CreateOfficeSize from "./Actions/CreateOfficeSize";

const AddNew = () => {
    const {setPageTitle} = useContext(PageTitleContext);

    useEffect(() => {
        setPageTitle('Add new');
    }, [])

    return (
        <div className="container-fluid">
            <div className="row g-7">
                <div className="col-xl-6">
                    <CreateBroker/>
                </div>
                <div className="col-xl-6">
                    <CreateOffice/>
                </div>
                <div className="col-xl-6">
                    <CreateOfficeSize/>
                </div>
            </div>

        </div>
)
}

export default AddNew
