import {Link, useNavigate} from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="d-flex flex-column flex-center flex-column-fluid p-10 full_height">
            <img src="/logo-dark.png" alt=""
                 className="mw-100 mb-20" />
            <h1 className="fw-bold mb-10 title-404" >Unauthorized</h1>
            <Link to="/"> <button className="btn btn-primary" onClick={goBack}>Return to Dashboard</button> </Link>
        </div>
    )
}

export default Unauthorized
