import {NavLink, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import AuthContext from "../context/AuthProvider";
import {useCookies} from "react-cookie";
import PageTitleContext from "../context/PageTitleProvider";
import HamburgerIcon from "./svgIcons/HamburgerIcon";
import MenuToggleContext from "../context/MenuToggleProvider";

const NavMenu = (props) => {
    const {setAuth} = useContext(AuthContext);
    const {pageTitle} = useContext(PageTitleContext);
    const navigate = useNavigate();
    const [cookie, setCookie] = useCookies(['token']);
    const {setMenuToggle} = useContext(MenuToggleContext);

    const logout = async () => {
        setAuth({});
        setCookie('token', '')
        navigate('/login');
    }

    const logoutAction = (e) => {
        e.preventDefault();
        logout()
    }

    return (
        <div id="kt_header" className="header align-items-stretch">
            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
                    <div onClick={() => setMenuToggle(true)} className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                         id="kt_aside_mobile_toggle">
                        <HamburgerIcon/>
                    </div>
                </div>
                <div className="d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                    <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Visits</h1>
                    <span className="h-20px border-gray-300 border-start mx-4"></span>
                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                        <li className="breadcrumb-item text-muted">
                            {pageTitle}
                        </li>
                    </ul>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <a onClick={logoutAction} className="btn btn-sm btn-primary">Logout</a>
                </div>
            </div>
        </div>

    )
}

export default NavMenu;