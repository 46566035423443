import {Form} from "react-bootstrap";
import {axiosPrivate} from "../../api/axios";
import {useState, useEffect} from "react";

const EditOfficeType = (props) => {
    const [officeType, setOfficeType] = useState();
    const [offices, setOffices] = useState();

    useEffect(() => {
        props.office({officeType})
    }, [officeType])

    useEffect(() => {
        setOfficeType(props.officeTypeData)
    }, [props.openModal])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getOffices = async () => {
            try {
                const response = await axiosPrivate.get('/client/office-type', {
                    signal: controller.signal
                });
                isMounted && setOffices(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getOffices();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const changeOfficeType = (val) => {
        setOfficeType(val)
    }

    return (
        <Form.Select aria-label="Default select example"
                     className="form-select form-select-solid select2-hidden-accessible"
                     onChange={(event => changeOfficeType(event.target.value))}
                     value={officeType}
        >
            {
                offices?.length
                    ? offices.map((office) => <option
                        key={office.id}
                        value={office.id}
                    >
                        {office.title}
                    </option>)

                    : ''
            }
        </Form.Select>
    )
}

export default EditOfficeType