import {useRef, useState, useEffect} from 'react';
import useAuth from '../hooks/useAuth';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {useCookies} from "react-cookie";
import {toast} from 'react-toastify';

import axios from '../api/axios';

const LOGIN_URL = '/auth/login';

const Login = () => {

    const [cookies, setCookie] = useCookies(['token']);
    const {setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');

    const [indicator, setIndicator] = useState(false);
    const notify = (msg) => toast.error(`${msg}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    ;

    useEffect(() => {
        userRef.current.focus();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIndicator(true);
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({email: user, password: pwd}),
                {
                    headers: {'Content-Type': 'application/json'},
                }
            );

            const accessToken = response?.data?.access_token;
            const roles = [response?.data?.role];
            const expirationDate = new window.Date(window.Date.now() + (response?.data?.expires_in * 60 * 1000));

            setAuth({user, pwd, roles, accessToken});
            setCookie('token', accessToken, {path: '/', expires:expirationDate });
            setCookie('roles', response?.data?.role, {path: '/', expires:expirationDate});
            setUser('');
            setPwd('');
            setIndicator(false);
            navigate(from, {replace: true});
        } catch (err) {
            if (!err?.response) {
                notify('No Server Response');
            } else if (err.response?.status === 400) {
                notify('Missing Username or Password');
            } else if (err.response?.status === 401) {
                notify('Wrong email or password!');
            } else {
                notify('Login Failed');
            }
            errRef.current.focus();
            setIndicator(false);
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div
                    className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 full_height">
                            <img alt="Logo" src="/logo-dark.png" className="h-40px mb-12"/>

                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form onSubmit={handleSubmit} className="form w-100" noValidate="novalidate"
                                  id="kt_sign_in_form">

                                <div className="text-center mb-10">

                                    <h1 className="text-dark mb-3">Sign In</h1>
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                                    <input
                                        type="text"
                                        id="username"
                                        ref={userRef}
                                        autoComplete="off"
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        className="form-control form-control-lg form-control-solid"
                                        required
                                    />
                                </div>
                                <div className="fv-row mb-10">
                                    <div className="d-flex flex-stack mb-2">
                                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                                    </div>
                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        className="form-control form-control-lg form-control-solid"
                                        required
                                    />
                                </div>
                                <div className="text-center">
                                    <button type="submit" id="kt_sign_in_submit"
                                            className="btn btn-lg btn-primary w-100 mb-5">
                                        {
                                            indicator
                                                ? <span
                                                    className="indicator-progress indicator_active">Please wait... <span
                                                    className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                : <span className="indicator-label">Continue</span>
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default Login
